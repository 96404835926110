import axios from '@axios';

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Draft',
      },
      {
        color: 'success',
        label: 'Approved',
      },
    ],
    selectedCalendars: ['Draft', 'Approved'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
  },
  actions: {
    fetchEvents(ctx) {
      const date = new Date();
      const beforeDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

      var events = [
        {
          id: 1,

          title: '23-08-2022 | uploaded results',
          start: beforeDay,
          end: beforeDay,
          allDay: true,
          extendedProps: {
            calendar: 'Draft',
          },
        },
        {
          id: 2,

          title: '24-08-2022 | uploaded results',
          start: date,
          end: date,
          allDay: true,
          extendedProps: {
            calendar: 'Draft',
          },
        },
        {
          id: 3,

          title: '24-08-2022 | uploaded results',
          allDay: true,
          start: date,
          end: date,
          extendedProps: {
            calendar: 'Approved',
          },
        },
      ];
      return events;
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
